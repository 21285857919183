import { View } from 'react-native'
import React, { FunctionComponent, } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { onPatch } from 'mobx-state-tree';
import { useDebouncedCallback } from 'use-debounce';
import { conditionIsGo } from '../../utils/conditionIsGo';

export const GroupElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element,
    } = initializeElement(props);
    const [show, setShow] = React.useState<boolean>(!element.requires || !element.requires.length);

    const checkCondition = React.useCallback(async () => {
        try {
            console.log(element.id, "verifica condicion 2", "checkCondition")            
            let missingCondition = false;
            if (element.requires?.length > 0 && element.dynamicRequires?.length>0){
                element.dynamicRequires[0].options = true;
                missingCondition = element.requires?.some((requisite: any) => (itemsStore.getPayloadElement(requisite.elementId, props.parentId, props.recordIdx)).value !== requisite.expectedValue) 
                    && !(conditionIsGo(element.dynamicRequires, itemsStore.itemActive.toJSON(), props.parentId, props.recordIdx)[0] == true);
            }
            else if (element.requires.length>0)
                missingCondition =  element.requires?.some((requisite: any) => (itemsStore.getPayloadElement(requisite.elementId, props.parentId, props.recordIdx)).value !== requisite.expectedValue);
            else if (element.dynamicRequires?.length>0){
                element.dynamicRequires[0].options = true;
                missingCondition = !(conditionIsGo(element.dynamicRequires, itemsStore.itemActive.toJSON(), props.parentId, props.recordIdx)[0]==true);
            }
            
            console.log(element.id, "verifica condicion 234",missingCondition)
            setShow(!(missingCondition));
            setTimeout(() => {
                let data = [];
                function walkNodes(nodes, missingCondition, data) {
                    for(let idx=0;idx<nodes.length;idx++){               
                        const node = nodes[idx];
                        console.log("checkCondition group",node.id,node.type)
                        if (["populate", "validate"].includes(node.type))
                            return;
                        if (missingCondition)
                            data.push({ fieldId: node.id, payloadElement: { value: null, inactiveGroup: missingCondition } })
                        else
                            data.push({ fieldId: node.id, payloadElement: { inactiveGroup: missingCondition } })
                        if (Array.isArray(node.nodes))
                            walkNodes(node.nodes, missingCondition, data)                    
                    }
                }
                walkNodes(element.nodes, missingCondition, data)
                console.log(element.id, "verifica condicion 2 out", "checkCondition")
                itemsStore.updPayloadElements(data, props.parentId, props.recordIdx);
                console.log(element.id, "verifica condicion 3 out", "checkCondition")
            }, 50);


        } catch (e) {
            console.log("GroupElement", "checkCondition", "", element.id, e)
        }
    }, []);
    const checkConditionDebouneced = useDebouncedCallback(checkCondition, 5);

    React.useEffect(() => {
        setTimeout(checkCondition, 5);
        let _disposerOnPatch = onPatch(itemsStore, patch => {
            console.log(element.id, "verifica condicion 1", patch)
            console.log(element.require)
            let changedSomeRequired = false;
            let fields = [].concat((element.dynamicRequires || []).flatMap(require => require.conditions.flatMap(a => a.rules || a))).concat(element.requires || []);
            fields.forEach(requisite => {
                console.log(element.id, "verifica condicion 1.1 Requisito", requisite)
                if (patch.path.includes(requisite.elementId || requisite.fieldId))
                    changedSomeRequired = true
            });
            if (changedSomeRequired) {
                console.log(element.id, "verifica condicion 2", patch)
                checkConditionDebouneced();
                console.log(element.id, "verifica condicion 3", patch)
            }
        });

        return () => {
            _disposerOnPatch();
        }
    }, [])

    return <ElementBase borderBottomWidth={0} element={element} forReview={props.forReview} hideTitleBar={true} >
        <View display={show ? "flex" : "none"}>{show ? props.children : null}</View>
    </ElementBase>
}
